<template>
  <!-- Action -->
  <div class="col-12 mb-2 col-md mb-md-0 text-center text-md-left">
    <div
      v-for="(item, i) in items"
      v-bind:key="i"
      v-bind:class="getClass(item)"
      v-on:click="onClick(item)"
    >
      <i v-bind:class="getIcon(item)"></i>
      <span v-if="item.text" class="ml-2">{{ item.text }}</span>
    </div>
  </div>
  <!-- ./Action -->
</template>

<script>
export default {
  name: "Actions",
  props: {
    items: {
      default: null,
    },
  },
  methods: {
    getClass(item) {
      let result = "btn btn-success hover-shadow mr-md-3";

      if (item && item.class) {
        result = item.class;
      }

      return result;
    },
    getIcon(item) {
      let result = "fas fa-plus";

      if (item && item.icon) {
        result = item.icon;
      }

      return result;
    },
    getPath(item) {
      let result = "";

      if (item && item.path) {
        result = item.path;
      }

      return result;
    },
    onClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else {
        this.$parent.$parent.$parent.$parent.$emit("on-click", item);
      }
    },
  },
};
</script>
